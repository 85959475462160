ACC.common = {
	currentCurrency: $("main").data('currencyIsoCode') || "USD",
	processingMessage: $("<img>").attr("src", ACC.config.commonResourcePath + "/images/spinner.gif"),


	blockFormAndShowProcessingMessage: function (submitButton) {
		var form = submitButton.parents('form:first');
		form.block({ message: ACC.common.processingMessage });
	},

	refreshScreenReaderBuffer: function () {
		// changes a value in a hidden form field in order
		// to trigger a buffer update in a screen reader
		$('#accesibility_refreshScreenReaderBufferField').attr('value', new Date().getTime());
	},

	checkAuthenticationStatusBeforeAction: function (actionCallback) {
		$.ajax({
			url: ACC.config.authenticationStatusUrl,
			statusCode: {
				401: function () {
					location.href = ACC.config.loginUrl;
				}
			},
			dataType: 'json',
			success: function (data) {
				if (data == "authenticated") {
					actionCallback();
				}
			}
		});
	},

	encodeHtml: function (rawText) {
		return rawText.toString()
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&#39;')
			.replace(/\//g, '&#47;');
	}
};

$(document).ready(function () {

	if (isElemExist("#js-site-search-input")) {
		if ($("#js-site-search-input").val().length > 0) {
			$(".PopularSearchesContainer").addClass("d-none");
		}
		else {
			$(".PopularSearchesContainer").removeClass("d-none");
		}
		$("#js-site-search-input").on("input", function () {
			$("#products").contents().remove();
			$("#suggesstion").contents().remove();
		})
	}
	$("#globalSearchDismiss,.site-search .cross-sign").click(function () {
		$("#js-site-search-input").val('');
		$("#products").contents().remove();
		$("#suggesstion").contents().remove();
		$("#SuggestionContainerId").addClass("d-none");
		$("#ProductContainerId").addClass("d-none");
		$(".site-search .cross-sign").addClass('d-none');
	})
	$('#SearchModel').on('shown.bs.modal', function () {
		$('#js-site-search-input').focus()
	})
	// Search Modal Toggler
	$('#globalSearchBtn').on("click", function () {
		$(window).scrollTop(0);
		updateSearchModal()
	});
});


/* Extend jquery with a postJSON method */
jQuery.extend({
	postJSON: function (url, data, callback) {
		return jQuery.post(url, data, callback, "json");
	}
});

// add a CSRF request token to POST ajax request if its not available
$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
	// Modify options, control originalOptions, store jqXHR, etc
	if (options.type === "post" || options.type === "POST") {
		var noData = (typeof options.data === "undefined");
		if (noData) {
			options.data = "CSRFToken=" + ACC.config.CSRFToken;
		}
		else {
			var patt1 = /application\/json/i;
			if (options.data instanceof window.FormData) {
				options.data.append("CSRFToken", ACC.config.CSRFToken);
			}
			// if its a json post, then append CSRF to the header.
			else if (patt1.test(options.contentType)) {
				jqXHR.setRequestHeader('CSRFToken', ACC.config.CSRFToken);
			}
			else if (options.data.indexOf("CSRFToken") === -1) {
				options.data = options.data + "&" + "CSRFToken=" + ACC.config.CSRFToken;
			}
		}

	}
});

// Global Spinner Global Functions
window.ACIMA = window.ACIMA || [];
ACIMA.loadingSpinnerShow = function () {
	jQuery('.full-page-spinner').show();
}
ACIMA.loadingSpinnerHide = function () {
	jQuery('.full-page-spinner').hide();
}

// Category Header Scripts
function updateCategoryContainer() {
	const CategoryContainer = $(".js-mainHeader .header-category__nav");
	if (isElemExist(CategoryContainer)) {
		const distanceFromTop = CategoryContainer.offset().top;
		const screenWidth = $(window).width();
		if (screenWidth >= 1023) {
			CategoryContainer.css("height", `calc(80vh - ${distanceFromTop}px)`);
		} else {
			CategoryContainer.css("height", `calc(${window.innerHeight}px - ${distanceFromTop}px)`);
		}
	}
}

$('.header-category').on("click", function () {
	$(this).toggleClass('active');
	$('.header-category__nav').toggleClass('hide').scrollTop(0);
	if ($(this).hasClass('active')) {
		$('body').addClass('body-fixed category-overlay');
		$('header').addClass('position-relative');
	}
	else {
		$('body').removeClass('body-fixed category-overlay');
		$('header').removeClass('position-relative');
	}
	updateCategoryContainer();
});

$('.header-category__nav__close, #globalSearchBtn').on("click", function () {
	$('.header-category__nav').addClass('hide');
	$('.header-category').removeClass('active');
	$('body').removeClass('body-fixed category-overlay');
	$('header').removeClass('position-relative');
});

// Updating CategoryContainer Size
$(window).on('resize', function () {
	updateCategoryContainer();
});

// Desktop top header dropdown js
$(document).ready(function(){
	$('.top-header-desktop .dropdown-title').click(function() {
		$(this).siblings('.dropdown-sub-menu').toggleClass("show");
		$('.top-header-desktop .dropdown-sub-menu').not($(this).siblings()).removeClass("show");
		$(this).toggleClass("active");
		$('.top-header-desktop .dropdown-title').not($(this)).removeClass("active");
	});

	const topDesktopHeader = $(".top-header-desktop");
	if (isElemExist(topDesktopHeader)) {
		$(document).on("click", function (e) {
			if (!$(e.target).is(".top-header-desktop .dropdown-list *")) {
			$(".top-header-desktop .dropdown-sub-menu").removeClass("show");
			$(".top-header-desktop .dropdown-title").removeClass("active");
			}
		});
	}
})
// Zipcode Modal 
$(document).ready(function(){
    var pageType = $('.pageTypeValue').val();
    var storeValue = $('.storeValue').val();
	var sessionZipcode = $('.sessionZipcode').val();

    if (pageType != undefined && (pageType == 'productList' || pageType == 'productDetails' ||
            pageType == 'search' || pageType == 'searchEmpty' || pageType == 'acimaCategoryListingPage' || pageType == 'cartPage') && (storeValue == undefined || storeValue == null || storeValue == '')
			&& (sessionZipcode == undefined || sessionZipcode == null || sessionZipcode == '')) {
        var closeButton = $('#StoreNumberModal button.close');
        closeButton.attr('style', 'display: none !important');
        $('#StoreNumberModal').modal({backdrop: 'static', keyboard: false}, 'show');
    }

	$('#enterZipCodeGoButton').click(function(e) {
        e.preventDefault();
        checkZipCode();
    });

	$('#enteredZipCode').on('keyup', function(event) {
        if (event.which === 13) {
            checkZipCode();
        }
    });

	function checkZipCode() {
		$('#enterZipCodeGoButton').attr('disabled', true);
		$zipCodeSpanError = $("#zipError");
		if (validateHeaderZipCode()) {
			var $zipCodeFieldID = $("#enteredZipCode"),
				$zipCodeSpanError = $("#zipError");
			$.ajax({
				url: ACC.config.encodedContextPath + '/store-finder/setStore',
				data: {
					enteredZipCode: $zipCodeFieldID.val()
				},
				type: "GET",
				success: function (data) {
					if (!isNullUndefined(data)) {
						if (!isNullUndefined($('.pageTypeValue').val()) && $('.pageTypeValue').val() == 'acimaLandingPage') {
							var newURL = window.location.href;
							if (newURL.endsWith('/')) {
								newURL = newURL + "shop"
							} else {
								newURL = newURL + "/shop"
							}
							location.href = newURL;
							$('#enterZipCodeGoButton').attr('disabled', false);
						} else {
							location.reload(true);
							$('#enterZipCodeGoButton').attr('disabled', false);
						}
					} else {
						$('#invalidZipError').addClass('d-none');
						$('#notAvilableError').removeClass('d-none');
						$('#zipErrorForChange').css({ "display": "none" });
						$('#enterZipCodeGoButton').attr('disabled', false);
						if (!isNullUndefined($('.vendorUrl').val())) {
							$("#enterZipCodeGoButton").hide();
							$("#redirectToVendorSide").css({ "display": "block" });
						}
					}
				},
				error: function (ex) {
					console.log("Error setting Store");
					$('#enterZipCodeGoButton').attr('disabled', false);
				}
			});
		} else {
			$('#invalidZipError').removeClass('d-none');
			$('#enterZipCodeGoButton').attr('disabled', false);
		}
	}

    $("#shopNowButton").click(function() {
        var sessionZipcode = $('.sessionZipcode').val();
        if (sessionZipcode == undefined || sessionZipcode == null || sessionZipcode == '') {
			$("#enteredZipCode").val(sessionZipcode);
            $('#StoreNumberModal').modal('show');
        } else {
             window.location = ACC.config.encodedContextPath + '/shop';
        }
    });

    // Mobile Header & Sidebar js
	$('.sidebar-mobile .dropdown-title').click(function() {
		$(this).siblings('.dropdown-sub-menu').toggleClass("show");
		$(this).toggleClass("active");
	});
	const menuOpenBtn = $("#mobile-menu");
	const sidebarMenu = $("#mobile-menu-sidebar");
	$(menuOpenBtn).click(function() {
		$(sidebarMenu).css("left","0");
		$("body").addClass("sidebar-overlay");
	});
	// Closing Sidebar
	if (isElemExist(sidebarMenu)) {
		$(document).on("click", function (e) {
			if ($("body").hasClass("sidebar-overlay") && menuOpenBtn.has(e.target).length === 0) {
				if (!sidebarMenu.is(e.target) && sidebarMenu.has(e.target).length === 0) {
					sidebarMenu.css("left", "-100%");
					$("body").removeClass("sidebar-overlay");
					$(".sidebar-mobile .dropdown-sub-menu").removeClass("show");
					$(".sidebar-mobile .dropdown-title").removeClass("active");
				}
			}
		});
	}
	// Mobile Nav header dropdown js
	$('.header-nav-user .login-user-logo').click(function() {
		$('.header-user-dropdown .dropdown-sub-menu').toggleClass("show");
	});
	const mobileNavHeader = $(".header-nav-user");
	if (isElemExist(mobileNavHeader)) {
		$(document).on("click", function (e) {
			if (!$(e.target).is(".header-nav-user *")) {
				$(".header-user-dropdown .dropdown-sub-menu").removeClass("show");
			}
		});
	}

	// when user will click on change zip code modal then below code will update the input field accordingly 
	var sessionZipcode = $('.sessionZipcode').val();
	if (sessionZipcode) {
		$("#enteredZipCode").val(sessionZipcode);
		$("#zipErrorForChange").removeAttr('style');
	}
})

//JS for Search Modal
function updateSearchModal() {
	const searchModalContentTop = $('.searchModalContainer .modal-content');
	const searchModalTop = $('.searchModalContainer .modal');
	const headerBotttom = $("header .header-bottom");
	if (isElemExist(headerBotttom)) {
		const headerHeight = $("header").height();
		const headerBotttomHeight = $("header .header-bottom").height();
		const headerBreadcrumb = $("header .breadcrumb-section");
		let headerBreadcrumbHeight = 0;
		let storeLocatorHeader = $(".shopping-store-wrapper");
		let storeLocatorHeaderHeight = 0
		if (isElemExist(headerBreadcrumb)) {
			headerBreadcrumbHeight = $("header .breadcrumb-section").height();
		}
		else {
			headerBreadcrumbHeight = 0;
		}
		if(isElemExist(storeLocatorHeader)) {
			storeLocatorHeaderHeight = storeLocatorHeader.innerHeight();
		}
		else {
			storeLocatorHeaderHeight = 0
		}
		const calcDistanceFromTop = (headerHeight - headerBotttomHeight - headerBreadcrumbHeight + storeLocatorHeaderHeight);
		const screenWidth = $(window).width();
		if (screenWidth >= 1023) {
			searchModalTop.css("top", calcDistanceFromTop);
			searchModalContentTop.css("height", `calc(80vh - ${calcDistanceFromTop}px)`);
			if($('body').hasClass('page-theme-amazon')) {
                searchModalContentTop.css("height", 'auto');
            }
        } else {
            if($('body').hasClass('page-theme-amazon')) {
                let headerNavMobile = $(".header-nav-mobile");
                let headerNavMobileHeight = 0
                if (isElemExist(headerNavMobile)) {
                    headerNavMobileHeight = headerNavMobile.innerHeight();
                }
                let headerTopCart = $(".header-top-cart");
                let headerTopCartHeight = 0;
                if (isElemExist(headerTopCart)) {
                    headerTopCartHeight = headerTopCart.height();
                }
                let searchTopDistanceMobile = storeLocatorHeaderHeight + headerNavMobileHeight + headerTopCartHeight;
                searchModalTop.css("top", searchTopDistanceMobile);
            }
            else {
                searchModalTop.css("top", 0);
                searchModalContentTop.css("height", '100vh');
            }
        }
	}
}

// Updating SearchModal Size
$(window).on('resize', function() {
	updateSearchModal();
});

// Generic Utility Functions
function isElemExist(elem) {
	return $(elem) !== null && $(elem) !== undefined && $(elem).length > 0;
}
function isNullUndefined(val) {
	return typeof val == 'string' && !val.trim() || val === undefined || val === null;
}
function isTrue(val) {
	return (val !== null || val !== undefined) && val !== '' && (val === true || val === 'true');
}
function isFalse(val) {
	return (val !== null || val !== undefined) && val !== '' && (val === false || val === 'false');
}
function isNotTrue(val) {
	return val !== true ? true : false;
}
function isNotFalse(val) {
	return val !== false ? true : false;
}
function isMobileDevice() {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || $(window).width() < 768;
}
$('#timeoutPopupModal').on('shown.bs.modal', function (e) {
	setTimeout(function () {
		window.location.href = ACC.config.encodedContextPath + '/timeout/error';
	}, $('#no_activity_timeout_timer').val());
})
setTimeout(function () {
	$('#timeoutPopupModal').modal('show');
}, $('#timeout_modal_timer').val());

$('#moreTimeReq').on('click', function () {
	$.ajax({
		url: ACC.config.encodedContextPath + '/ping',
		type: "GET",
		success: function (data) {
			console.log("Successfully pinged server");
			$('#timeoutPopupModal').modal('hide');
		},
		error: function (ex) {
			console.log("Failed to ping server");
		}
	});
});

// Generic method to show error alerts
function showGlobalErrorMsg(imgName, msg) {
	let newErrorMsg = $(".alert-cart-container.ajax-error").eq(0).clone();
	let imgBasePath = $(".alert-cart-container.ajax-error").eq(0).find("#errorMsgImg").attr("data-src");
	$(".alert-cart-container").last().after(newErrorMsg);
	$(".alert-cart-container").last().find("#errorMsgImg").attr("src", imgBasePath + imgName);
	$(".alert-cart-container").last().find("#ajaxErrorMsg").html(msg);
	$(".alert-cart-container").last().removeClass("hidden");
}

// Function to load JS Scripts on demand but once
var loadedScriptsArray = [];
function loadScriptOnDemand(url, callback) {
	if (loadedScriptsArray.indexOf(url) === -1){
		if (typeof callback === 'function') {
			return $.getScript(url, function(script, textStatus, jqXHR) {
				loadedScriptsArray.push(url);
				callback(script, textStatus, jqXHR);
			});
		} else {
			return $.getScript(url, function(){
				loadedScriptsArray.push(url);
			});
		}
	} else {
		return {
			done: function () {
				return {
					fail: function () {}
				};
			}
		};
	}
}
$(window).on('load', function() {
	setTimeout(function () {
		const deviceDetected = $('#isMobileAppRequest').attr('data-value');
		if (!isNullUndefined(deviceDetected) && isFalse(deviceDetected)) {
			let chatParamsarray = $('#chatInitParams').attr('data-value');
			chatParamsarray = chatParamsarray.split(',');
			loadScriptOnDemand($('#chatEmbedUrl').attr('data-value'), function () {
				icPatronChat.init({
					serverHost: $('#chatInitServerHost').attr('data-value'),
					bus_no: Number($('#chatInitBusNo').attr('data-value')),
					poc: $('#chatInitPoc').attr('data-value'),
					params: chatParamsarray
				});
			});
		}
	}, Number($('#chatDelayTime').attr('data-value')));
});



function validateHeaderZipCode() {
    var zipCodeChk = $('#enteredZipCode').val();
    var zipCodeRegex = /^\d{5}$/;
    if (zipCodeRegex.test(zipCodeChk)) {
    	return true;
    } else {
    	return false;
    }
}

//Function is called from the store locator modal input.
function handlestorelocatorInput(currentValue) {
    if ($('#notAvilableError').is(':visible')) {
        if(currentValue.length < 5) {
            $("#enterZipCodeGoButton").css({ "display": "" });
            $("#redirectToVendorSide").css({ "display": "none" });
            $("#notAvilableError").addClass('d-none');
        }
    }
}
//Code to hide Search Modal On Document Click
$(document).on("click", function (e) {
	if (!$(e.target).is(".header-bottom *")) {
		$("#SearchModel").modal("hide");
	}
});

$(document).ready(function () {
	$("#goShoppingBtn").on("click", function () {
		window.location.href = ACC.config.encodedContextPath + '/shop';
	});
})